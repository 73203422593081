import React from "react";
import { Link as LL } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container as ContainerBase } from "components/common/Layouts.js"
import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";
import { ReactComponent as QuoraIcon } from "../../images/quora-icon.svg";


const LinksContainer = tw.div`mt-8 text-gray-600 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;


const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 sm:text-base text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Container = tw(ContainerBase)`bg-gray-200 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Row = tw.div`flex items-center justify-center flex-col px-8`
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
//const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`

export default () => {
  return (

    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Learning Beam Academy</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            {/* <Link href="#">About</Link> */}
            <Link href="/contact">Contact Us</Link>
            <Link href="/blogs">Blog</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/Learning-Beam-Academy-109020948153516" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/Learning_beam" target="_blank">
              <TwitterIcon />
            </SocialLink>
            {/* <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
            <SocialLink href="https://www.instagram.com/learningbeamacademy/" target="_blank">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://www.quora.com/profile/Learning-Beam-Academy/" target="_blank">
              <QuoraIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2021, Learning Beam Academy. All Rights Reserved.
        </CopyrightText>
        </Row>
      </Content>
    </Container>

  );
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../common/Header.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

//import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
//import { ReactComponent as PlayIcon1 } from "../../images/chevron-right.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";

import usaImage from "../../images/us.png";
import canadaImage from "../../images/canada.png";


const Container = tw.div`relative px-5 md:px-12`;
// No Padding on Left and Right
//const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
// Paddding on Left & Right
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-4`;
//const LeftColumn = tw.div`relative lg:w-6/12 xl:pr-12 flex-shrink-0 text-center lg:text-left`;
const LeftColumn = tw.div`relative lg:w-6/12 xl:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h2`text-secondary-450 text-3xl lg:text-4xl xl:text-5xl font-medium leading-tight max-w-4xl font-serif`;
const Paragraph = tw.p`my-5 xl:my-6 text-sm lg:text-base font-normal text-secondary-250 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-8 py-3 rounded bg-primary-500 text-white hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const SvgDotPattern1 = tw(
    SvgDecoratorBlob2
)`absolute top-0 left-0 transform -translate-x-20 rotate-45 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24 mt-16`;
const SvgDotPattern2 = tw(
    SvgDecoratorBlob2
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const HeadingContainer = tw.div``;

const Title = tw.h4`text-3xl font-normal text-secondary-450 text-left font-serif`;

const CountryTitle = tw.h5`text-2xl font-normal text-secondary-450 text-left font-serif mt-6`;

const CountryFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm text-left`}
  .feature {
    ${tw`mt-3 first:mt-0 font-normal text-secondary-250`}
  }
`;

export default ({
    heading = "Study Destinations",
    description = "Study according to your schedule, get the perfect guidance and pass with flying colors.",
    primaryButtonText = "Register",
    primaryButtonUrl = "#",
    watchVideoButtonText = "Get a Free Demo",
    watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",
    imageSrc = DesignIllustration,
    imageCss = null,
    imageDecoratorBlob = false,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const features = ["60 Templates", "8 Landing Pages", "22 Internal Pages", "Priority Assistance", "Lifetime Updates"];

    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    return (
        <>
            <Container>
                <HeadingContainer>
                    <Title>{heading}</Title>
                </HeadingContainer>
                <TwoColumn style={{ paddingBottom: '0' }}>
                    <LeftColumn>
                        <IllustrationContainer>
                            <img
                                src={usaImage}
                                alt="USA"
                            />
                        </IllustrationContainer>
                        <CountryTitle>{"Study in US"}</CountryTitle>
                        <CountryFeatures>
                            {features.map((feature, index) => (
                                <span key={index} className="feature">
                                    {feature}
                                </span>
                            ))}
                        </CountryFeatures>
                    </LeftColumn>
                    <RightColumn>
                        <IllustrationContainer>
                            <img
                                src={canadaImage}
                                alt="Hero"
                            />
                        </IllustrationContainer>
                        <CountryTitle>{"Study in Canada"}</CountryTitle>
                        <CountryFeatures>
                            {features.map((feature, index) => (
                                <span key={index} className="feature">
                                    {feature}
                                </span>
                            ))}
                        </CountryFeatures>
                    </RightColumn>
                </TwoColumn>
                {/* <SvgDotPattern1 />
        <SvgDotPattern2 /> */}
                <StyledModal
                    closeTimeoutMS={300}
                    className="mainHeroModal"
                    isOpen={modalIsOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <CloseModalButton onClick={toggleModal}>
                        <CloseIcon tw="w-6 h-6" />
                    </CloseModalButton>
                    <div className="content">
                        <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
                    </div>
                </StyledModal>
            </Container>
        </>
    );
};

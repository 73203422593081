import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Header from "components/common/Header.js";
import ContactBasic from "components/contact/ContactBasic";
import MetaTags from 'react-meta-tags';

export default () => {
	return (
		<AnimationRevealPage>
			<title>IELTS, TOEFL, CELPIP &amp; CAEL: Which English test should you take?</title>
			<meta name="description" content="IELTS, TOEFL, CELPIP & CAEL Online Coaching - Learning Beam Academy is the Best IELTS, TOEFL, CELPIP & CAEL Coaching in Canada, well-known for IELTS, TOEFL, CELPIP & CAEL Online Training, IELTS, TOEFL, CELPIP & CAEL Online Coaching & IELTS, TOEFL, CELPIP & CAEL Online preparation." />
			<meta name="keywords" content="IELTS, TOEFL, CELPIP, CAEL, Best Online Schooling in Ontario, Online Schooling in Ontario, Online Schooling, Ontario Best Online virtual high school, Best Ontario Online School, Credit Courses, Grade 9, Grade 10, Grade 11, Grade 12, Free online classes" />
			<meta property="og:title" content="IELTS, TOEFL, CELPIP &amp; CAEL: Which English test should you take?" />
			<meta property="og:description" content="IELTS, TOEFL, CELPIP & CAEL Online Coaching - Learning Beam Academy is the Best IELTS, TOEFL, CELPIP & CAEL Coaching in Canada, well-known for IELTS, TOEFL, CELPIP & CAEL Online Training, IELTS, TOEFL, CELPIP & CAEL Online Coaching & IELTS, TOEFL, CELPIP & CAEL Online preparation." />
			<meta property="og:image" content="/static/media/logo.358fb4cd.svg" alt="best online schooling in Ontario" />
			<Header />
			<ContactBasic />
			<Footer />
		</AnimationRevealPage>
	);
}

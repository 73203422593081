import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Header from "components/common/Header.js";

const styles = { width: "100%", height: "84rem" }
const URL = "https://form.jotform.com/212801748144453"

export default () => {
    return (
        <AnimationRevealPage>
            <Header />
            <iframe title="your title" style={styles} src={URL}></iframe>
            <Footer />
        </AnimationRevealPage>
    );
}

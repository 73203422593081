import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Desc from "components/internationals/Desc.js";
import BestPart from "components/common/BestPart.js";
import AboutUs from "components/common/AboutUs.js";
import bestPartImage from "../../images/best_part.png";

import HowItWorks from "components/internationals/HowItWorks.js";

import WhyUs from "components/internationals/WhyUs.js";
import Destinations from "components/internationals/Destinations.js";
import OurColleges from "components/internationals/OurColleges.js";

import Top from "components/internationals/MainTop.js";
import courseMainImage from "../../images/inter.png";
import aboutUsImage from "../../images/aboutus.png"
const imageCss = tw`rounded-4xl`;

class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<AnimationRevealPage>
				<Top
					heading={<>Overseas Studies</>}
					description="Please fill the form below to complete the registration process. Login instructions will be mailed to you once you are enrolled in your course."
					primaryButtonUrl="/register"
					imageSrc={courseMainImage}
					imageCss={imageCss}
					imageDecoratorBlob={true}
				/>
				<hr style={{ width: '83%', marginLeft: '2.5em' }} />
				{/* <Desc /> */}
				<WhyUs />
				<Destinations />
				<HowItWorks />
				<OurColleges />
				< AboutUs
					imageSrc={aboutUsImage}
					textOnLeft={false} />
				{/* <MoreAboutUs /> */}
				{/* <BestPart
					imageSrc={bestPartImage}
					imageCss={imageCss}
					imageDecoratorBlob={true}
					primaryButtonText="Register"
					watchVideoButtonText="Get a Free Demo"
				/> */}
				<Footer />
			</AnimationRevealPage>
		)
	}
}
export default (About);

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as svg220 } from "images/220.svg";
//import svg220 from "../../images/220.svg";
const Container = tw.div`relative md:px-10`
//const Container = tw.div`relative`

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row md:mt-8`;
const Header = tw(SectionHeading)``;
//const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabsControl = tw.div`flex flex-wrap px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-150 font-normal rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`border-b-2! border-primary-100 text-primary-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-base md:text-lg  font-semibold group-hover:text-primary-500 items-center text-secondary-450`;
const CardContent = tw.p`mt-1 text-sm font-medium text-secondary-250`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob2 = styled(svg220)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-80 w-80 transform translate-x-2/3 text-primary-500`}
`;
const Title = tw.h4`text-3xl font-bold text-gray-700 text-center`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-blue-100 text-secondary-450 focus:bg-blue-100 focus:shadow-outline focus:outline-none transition duration-300 cursor-default pointer-events-none`;
const textOnLeft = true;
const selectButtonText = "Select Tuition1";
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const leftDescription = "* should be same as your Government-issued identification.";
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const styles = { width: "100%", height: "182rem" }

const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

export default ({
  heading = "",
  tabs = {
    1: getRandomCards1(),
    2: getRandomCards2(),
    3: getRandomCards3(),
    4: getRandomCards4(),
    5: getRandomCards5(),
    6: getRandomCards6(),
    7: getRandomCards7(),
    8: getRandomCards8(),
    9: getRandomCards9(),
    10: getRandomCards10(),
    // 11: getRandomCards11(),
    // 12: getRandomCards12()
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);




  return (
    <Container>
      <DecoratorBlob2 />
      <ContentWithPaddingXl>

        {/* <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <PrimaryButton as="a">{selectButtonText}</PrimaryButton>
            <Description className="contactUsHeadingRegular" style={{ marginTop: '2em', marginBottom: '2em' }}>{leftDescription}</Description>
            <iframe title="your title" style={styles} src={URL}></iframe>
          </TextContent>
        </TextColumn> */}

        <PrimaryButton>{selectButtonText}</PrimaryButton>
        <HeaderRow>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                Grade {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>


        {tabsKeys.map((tabKey, index) => (
          <TabContent key={index} variants={{ current: { opacity: 1, scale: 1, display: "flex" }, hidden: { opacity: 0, scale: 0.8, display: "none", } }} transition={{ duration: 0.4 }} initial={activeTab === tabKey ? "current" : "hidden"} animate={activeTab === tabKey ? "current" : "hidden"} className="homeCoursesTabContent">
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Link to={card.url}>
                  <Card className="group courseCard d-flex" initial="rest" whileHover="hover" animate="rest">
                    <CardText className="courseCardText courseCardPaddingTop">
                      <CardTitle>{card.title}</CardTitle>
                      <CardContent>{card.content}</CardContent>
                    </CardText>
                  </Card>
                </Link>
              </CardContainer>
            ))}
            {/* <img className="round-full" src={svg220} style={{ zIndex: '-1', margin: 'auto', marginRight: '0px' }} /> */}
          </TabContent>

        ))}

      </ContentWithPaddingXl>

    </Container>
  );
};

const getRandomCards1 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR1"
    },
    {
      title: "English",
      content: "English",
      url: "course/course_code=GR1"
    }
  ];
  return cards;
};

const getRandomCards2 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR2"
    },
    {
      title: "English",
      content: "English",
      url: "course/course_code=GR2"
    }
  ];
  return cards;
};

const getRandomCards3 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR3"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR3"
    // }
  ];
  return cards;
};

const getRandomCards4 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR4"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR4"
    // }
  ];
  return cards;
};

const getRandomCards5 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR5"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR5"
    // }
  ];
  return cards;
};

const getRandomCards6 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR6"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR6"
    // }
  ];
  return cards;
};

const getRandomCards7 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR7"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR7"
    // }
  ];
  return cards;
};


const getRandomCards8 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR8"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR8"
    // }
  ];
  return cards;
};

const getRandomCards9 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR9"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR9"
    // }
  ];
  return cards;
};

const getRandomCards10 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR10"
    },
    // {
    //   title: "English",
    //   content: "English",
    //   url: "course/course_code=GR10"
    // }
  ];
  return cards;
};



const getRandomCards11 = () => {
  const cards = [
    {
      title: "Math",
      content: "Math",
      url: "course/course_code=GR11"
    },
    {
      title: "English",
      content: "English",
      url: "course/course_code=GR11"
    }
  ];
  return cards;
};




const getRandomCards12 = () => {
  const cards = [
    {
      title: "English",
      content: "English of University Preparation",
      url: "course/course_code=ENG4U"
    },

    {
      title: "Mathematics",
      content: "Mathematics of Data Management",
      url: "course/course_code=MDM4U"
    },
    {
      title: "Chemistry",
      content: "Chemistry University Preparation",
      url: "course/course_code=SCH4U"
    },
    {
      title: "Physics",
      content: "Physics University Preparation",
      url: "course/course_code=SPH4U"
    },
    {
      title: " Advanced Functions",
      content: " Advanced Functions",
      url: "course/course_code=MHF4U"
    },
    {
      title: "Calculus & Vectors",
      content: "Calculus & Vectors",
      url: "course/course_code=MCV4U"
    }


  ];

  // Shuffle array
  //return cards.sort(() => Math.random() - 0.5);
  return cards;
};



import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import Home from "components/home/Main.js";
import AboutUs from "components/about/Main.js";
import Courses from "components/course/Courses.js";
import CoursesMain from "components/course/Main.js";
import Success from "components/misc/Success.js";
import EnrolledFreeSuccess from "components/misc/Success.js";
import Payment from "components/course/Payment.js";
import CoursePayment from "components/course/CoursePayment.js";
import StatsBACK from "components/common/StatsBACK.js";
import Grades from "components/grade/Main.js";

import Career from "components/career/Main.js";
import PostCV from "components/career/PostCV";

import Register from "components/common/Register";
import RegisterFree from "components/common/RegisterFree";
import InternationalStudents from "components/internationals/Main.js";
import InternationalNewStudents from "components/internationals_new/Main.js";
import LangCourses from "components/lang_course/Main.js";
import ProfCourses from "components/prof_course/Main.js";
import Contact from "components/contact/Main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={LangCourses}>
				</Route>
				<Route exact path="/about">
					<AboutUs />
				</Route>
				<Route exact path="/course/:course_code" component={Courses}></Route>
				<Route exact path="/courses" component={CoursesMain}>
				</Route>
				<Route exact path="/lang_courses" component={LangCourses}>
				</Route>
				<Route exact path="/prof_courses" component={ProfCourses}>
				</Route>
				<Route exact path="/success">
					<Success />
				</Route>
				<Route exact path="/register">
					<Register />
				</Route>
				<Route exact path="/postcv">
					<PostCV />
				</Route>
				<Route exact path="/payment">
					<Payment />
				</Route>
				<Route exact path="/course-payment">
					<CoursePayment />
				</Route>
				<Route exact path="/free-demo">
					<RegisterFree />
				</Route>
				<Route exact path="/stats-test">
					<StatsBACK />
				</Route>
				<Route exact path="/grade/:grade" component={Grades}>
				</Route>
				<Route exact path="/international-students">
					<InternationalStudents />
				</Route>
				<Route exact path="/contact">
					<Contact />
				</Route>
				<Route exact path="/enrolled-successfully">
					<EnrolledFreeSuccess />
				</Route>
				<Route exact path="/career" component={Career}></Route>
			</Switch>
		</Router >
	);
}


import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Desc from "components/lang_course/Desc.js";
import BestPart from "components/common/BestPart.js";
import AboutUs from "components/common/AboutUs.js";
import bestPartImage from "../../images/best_part.png";

import Features from "components/lang_course/Features.js";
import HowItWorks from "components/lang_course/HowItWorks.js";

import WhyUs from "components/lang_course/WhyUs.js";
import Destinations from "components/lang_course/Destinations.js";
import OurCourses from "components/lang_course/OurCourses.js";
import Top from "components/lang_course/MainTop.js";
import mainImage from "../../images/lang_course_main.png";
import MetaTags from 'react-meta-tags';

const imageCss = tw`rounded-4xl`;

class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<AnimationRevealPage>
				<MetaTags>
					<title>IELTS, TOEFL, CELPIP &amp; CAEL: Which English test should you take?</title>
					<meta name="description" content="IELTS, TOEFL, CELPIP & CAEL Online Coaching - Learning Beam Academy is the Best IELTS, TOEFL, CELPIP & CAEL Coaching in Canada, well-known for IELTS, TOEFL, CELPIP & CAEL Online Training, IELTS, TOEFL, CELPIP & CAEL Online Coaching & IELTS, TOEFL, CELPIP & CAEL Online preparation." />
					<meta name="keywords" content="IELTS, TOEFL, CELPIP, CAEL, Best Online Schooling in Ontario, Online Schooling in Ontario, Online Schooling, Ontario Best Online virtual high school, Best Ontario Online School, Credit Courses, Grade 9, Grade 10, Grade 11, Grade 12, Free online classes" />
					<meta property="og:title" content="IELTS, TOEFL, CELPIP &amp; CAEL: Which English test should you take?" />
					<meta property="og:description" content="IELTS, TOEFL, CELPIP & CAEL Online Coaching - Learning Beam Academy is the Best IELTS, TOEFL, CELPIP & CAEL Coaching in Canada, well-known for IELTS, TOEFL, CELPIP & CAEL Online Training, IELTS, TOEFL, CELPIP & CAEL Online Coaching & IELTS, TOEFL, CELPIP & CAEL Online preparation." />
					<meta property="og:image" content="/static/media/logo.358fb4cd.svg" alt="best online schooling in Ontario" />
				</MetaTags>
				<Top
					heading={<>Take your learning to next level</>}
					description="Start your journey to live,work and study in Canada"
					imageSrc={mainImage}
					imageCss={imageCss}
					primaryButtonText="Get a free quote"
					primaryButtonUrl="/register"
					imageDecoratorBlob={true}
				/>
				<hr style={{ width: '83%', marginLeft: '2.5em' }} />
				<OurCourses />
				<WhyUs />

				<Features
					heading={
						<>
							Key features
          				</>
					}
					imageContainerCss={tw`p-2!`}
					imageCss={tw`w-20! h-20!`}
				/>

				<Destinations />
				<Footer />
			</AnimationRevealPage>
		)
	}
}
export default (About);

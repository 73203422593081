import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { SectionDescription } from "components/common/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"
import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import { ReactComponent as svg220 } from "images/220.svg";

//import SupportIconImage from "../../images/support-icon.svg";
import GirlIconImage from "../../images/whyus_icons/girl.svg";
import HomeIconImage from "../../images/whyus_icons/home.svg";
import BookIconImage from "../../images/whyus_icons/book.svg";
import EditIconImage from "../../images/whyus_icons/edit.svg";
import VisaIconImage from "../../images/whyus_icons/visa.svg";
import SupportIconImage from "../../images/whyus_icons/support.svg";

import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/shield-icon.svg";
import ReliableIconImage from "../../images/shield-icon.svg";
import SimpleIconImage from "../../images/shield-icon.svg";

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 text-gray-100 bg-primary-300 px-5 md:px-10 mx-2 md:mx-12 mb-20 rounded-2xl mt-16 pb-10`;


const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-2`}
`;

const HeadingContainer = tw.div`pt-12`;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-secondary-250 text-sm lg:text-base font-normal`;

const Title = tw.h4`sm:text-2xl md:text-3xl font-normal text-gray-700 text-center font-serif`;


const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-start px-6 py-10 mt-12 bg-white`}
  .imageContainer {
    ${tw`text-left rounded-full flex-shrink-0 relative`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .textContainer {
    ${tw`mt-4 text-left`}
  }

  .title {
    ${tw`mt-2 font-semibold text-base leading-none text-secondary-450`}
  }

  .description {
    ${tw`mt-2 font-normal text-secondary-250 text-sm leading-normal`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const DecoratorBlob2 = styled(svg220)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-80 w-80 transform translate-x-2/3 text-primary-500`}
`;

export default ({
  // subheading = "",
  heading = "Why us?",
  description = "We engage students through our enrichment, remedial and support programs. We provide instruction for elementary pupils for all strands identified by the Ontario Ministry of Education curricula guidelines. Our programs are designed to support the Canadian Curriculum subjects that include: Reading, Writing, Math, Science, Social –Studies, French, Technology, and Media Literacy.",
}) => {

  const cards = [
    {
      imageSrc: GirlIconImage,
      title: "Easy application",
      description: "Create your application with providing your minimal information."
    },
    {
      imageSrc: HomeIconImage,
      title: "Career Counselling",
      description: "Our experienced counsellors help students by understanding their current interest and route them to most suitable programs in their favourite universities."
    },
    {
      imageSrc: BookIconImage,
      title: "Prime Colleges and Universities",
      description: "Associated with almost all the elite colleges and universities."
    },
    {
      imageSrc: EditIconImage,
      title: "Financial Advice and Guidance",
      description: "Our expert financial advisors guide you to handle in financial need or road block."
    },
    {
      imageSrc: VisaIconImage,
      title: "Visa Assistance",
      description: "We offer visa assistance to our clients too."
    },
    {
      imageSrc: SupportIconImage,
      title: "Continuous Support",
      description: "We hold your hand in your complete journey."
    }
  ];

  return (
    <PrimaryBackgroundContainer>

      <HeadingContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Title>{heading}</Title>
      </HeadingContainer>
      <DecoratorBlob2 />
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </PrimaryBackgroundContainer>
  );
};

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { SectionDescription } from "components/common/Typography.js";
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import first from "../../images/home_icons/1.svg";
import second from "../../images/home_icons/2.svg";
import third from "../../images/home_icons/3.svg";
import four from "../../images/home_icons/4.svg";
//import five from "../../images/home_icons/5.svg";
import bitMap from "../../images/Bitmap.png";
import svg220 from "../../images/220.svg";

const Title = tw.h4`font-serif text-3xl font-normal text-secondary-450 text-center pt-10 pb-8`;
const SubTitle = tw.h4`mt-8 leading-relaxed font-bold text-lg`;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;

//const TwoColumnContainer = styled.div`
//${ tw`flex flex-wrap -mx-1 overflow-hidden` }
//`;
const TwoColumnContainer = styled.div`
${ tw`flex flex-wrap -mx-1 md:px-6 py-1 justify-center m-0`}
`;
//const Column = styled.div`
//  ${tw`lg:w-1/3 max-w-xs`}
//`;
// const Column = styled.div`
//   ${tw`my-1 px-1 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4`}
// `;

const Column = styled.div`
  ${tw`my-1 px-1 w-full overflow-hidden sm:h-48 h-64 lg:h-48 m-4 lg:m-8 md:w-2/5 lg:w-2/5 xl:w-2/5`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-2 px-2 py-2 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-200`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  // .link {
  //   ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
  //   .icon {
  //     ${tw`ml-2 w-4`}
  //   }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

const Feature = tw.div`mt-8 flex items-start flex-row`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block text-center rounded-full p-6 flex-shrink-0 bg-blue-100`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-blue-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }
`;

const FeatureText = tw.div`mt-2 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold  text-base text-secondary-450 text-left sm:ml-2`;
const FeatureDescription = tw.div`mt-1 text-xs text-secondary-250 text-left sm:ml-2`;

export default ({
  cardsRow1 = [
    {
      imageSrc: first,
      title: "Ontario Certified Teacher",
      description: "We have have a team qualified teachers which are all certified by Ontario Ministry Certification.",
      url: ""
    },
    {
      imageSrc: second,
      title: "Canadian Curriculum",
      description: "Our programs are designed to support the Canadian Curriculum subjects that include: Reading, Writing, Math, Science, Social Studies, French, Technology, and Media Literacy.",
      url: ""
    },
  ],
  cardsRow2 = [
    {
      imageSrc: third,
      title: "Individual Attention",
      description: "We provide attention to each student individually, understand the area where a student require improvement and work on it.",
      url: ""
    },
    {
      imageSrc: four,
      title: "Love & Care",
      description: "Safe, city approved and monitored premises which ensures well-being of your child.",
      url: ""
    },
  ],
  linkText = "Learn More",
  heading = "",
  imageContainerCss = null,
  imageCss = null
}) => {

  const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
  return (
    <Container style={{ marginLeft: '-2rem', marginRight: '-2rem' }}>
      <ContentWithPaddingXl style={{ paddingTop: '0' }}>
        {/*{heading && <Heading  >{heading}</Heading>}*/}
        <Title>{heading}</Title>
        <img class="round-full" src={svg220} style={{ position: 'absolute' }} />
        <TwoColumnContainer>
          {cardsRow1.map((card, i) => (
            <Column style={{ "borderRadius": "10px", "backgroundColor": "#FFFFFF", "boxShadow": "0 17px 34px 0 rgba(182,182,182,0.2)", marginTop: "1em", zIndex: '1' }} key={i}>
              <Card href={card.url}>

                <Feature key={i}>
                  {/* <span className="imageContainer" style={{
                    "width:30%; backgroundColor": "rgb(229, 241, 255)", "padding": "2.0rem"
                  }}>
                    <img class="round-full" src={card.imageSrc} />
                  </span> */}
                  <FeatureIconContainer>{<img class="round-full" src={card.imageSrc} />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{card.title}</FeatureHeading>
                    <FeatureDescription>{card.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              </Card>
            </Column>
          ))}
        </TwoColumnContainer>
        <TwoColumnContainer>
          {cardsRow2.map((card, i) => (
            <Column style={{ "borderRadius": "10px", "backgroundColor": "#FFFFFF", "boxShadow": "0 17px 34px 0 rgba(182,182,182,0.2)", "height": "180px", "margin": "2em", marginTop: "1em", zIndex: '1' }} key={i}>
              <Card href={card.url}>

                <Feature key={i}>
                  {/* <span className="imageContainer" style={{ "backgroundColor": "rgb(229, 241, 255)", "padding": "2.0rem" }}>
                    <img src={card.imageSrc} />
                  </span> */}
                  <FeatureIconContainer>{<img class="round-full" src={card.imageSrc} />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{card.title}</FeatureHeading>
                    <FeatureDescription>{card.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              </Card>
            </Column>
          ))}
        </TwoColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};

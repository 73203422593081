import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";

import Header from "components/common/Header.js";
import Stats from "components/common/Stats_New.js";
import Unique from "components/common/Unique.js";
import BestPart from "components/common/BestPart.js";
import Desc from "components/about/Desc.js";
import Faq from "components/about/Faq.js";
import FaqNew from "components/about/Faqback.js";

import bookOpen from "../../images/book-open.png";
import bitMap from "../../images/Bitmap.png";
import barChart from "../../images/bar-chart.png";
import checkCircle from "../../images/check-circle.png";
import aboutImage from "../../images/about_1.png";

const imageCss = tw`rounded-4xl`;
class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<AnimationRevealPage>
				<Header />
				<Desc />
				<Stats />
				<Unique
					heading={
						<>
							What makes us unique?
					</>
					}
					cards={[
						{
							imageSrc: bookOpen,
							title: "Multiple Courses",
						},
						{
							imageSrc: bitMap,
							title: "Ontario Module",
						},
						{
							imageSrc: barChart,
							title: "Gap Analytics",
						},
						{
							imageSrc: checkCircle,
							title: "Live Assessment",
						}
					]}
					imageContainerCss={tw`p-2!`}
					imageCss={tw`w-20! h-20!`}
				/>
				<br />
				<Faq heading="Frequently Asked Questions" />
				<BestPart
					imageSrc={aboutImage}
					imageCss={imageCss}
					imageDecoratorBlob={true}
					primaryButtonText="Register"
					watchVideoButtonText="Get a Free Demo"
				/>
				{/* <FaqNew /> */}
				<Footer />
			</AnimationRevealPage>
		)
	}
}
export default (About);

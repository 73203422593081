import React, { Component } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/building.jpg"
import Header from "components/common/Header.js";
import CourseTop from "components/course/Top.js";
import CoursesDetails from "components/course/Details.js";
import Payment from "components/course/Payments.js";
import "../../config.js";
import axios from "axios";
const qs = require('querystring');

class Courses extends Component {
	constructor() {
		super();
		document.title = "Course - Learning Beam";
		this.state = {
			showPayment: false,
			fee: '',
		}
	}

	onUpdate(data) { console.log(data); this.setState({ showPayment: true, fee: data, }) }

	render() {
		return (
			<AnimationRevealPage>
				<Header />
				{this.state.showPayment != true ?
					<>
						{/*<CourseTop data={this.props.match.params.course_code} imageSrc={serverSecureIllustrationImageSrc} onUpdate={this.onUpdate.bind(this)}/> */}
						<CourseTop data={this.props.match.params.course_code} imageSrc={serverSecureIllustrationImageSrc} />
						<CoursesDetails data={this.props.match.params.course_code} />
					</>
					: (<Payment data={this.state.fee} />)}
				<Footer />
			</AnimationRevealPage>
		);
	}
}
export default (Courses);

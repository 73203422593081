import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../common/Header.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as PlayIcon1 } from "../../images/chevron-right.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 px-5 md:px-10`;
const LeftColumn = tw.div`relative lg:w-6/12 xl:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 flex flex-col justify-center`;

const Heading = tw.h1`font-black text-3xl lg:text-4xl xl:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-2 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0 leading-5`;
const SubheadingDesc1 = tw.p`my-2 text-sm font-medium text-gray-600 max-w-lg mx-auto lg:mx-0 leading-5`;

const SubheadingDesc = tw.div`mt-1 text-sm text-gray-600`;
const Subheading = tw.div`font-bold text-base text-primary-500`;
const Subheading1 = tw.h5`font-semibold text-base lg:text-lg xl:text-xl  max-w-2xl leading-3`;




//const Heading = tw.h1`font-black text-3xl lg:text-4xl xl:text-5xl leading-snug max-w-4xl`;
//const Paragraph = tw.p`my-5 xl:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const SvgDotPattern1 = tw(
  SvgDecoratorBlob2
)`absolute top-0 left-0 transform -translate-x-20 rotate-45 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24 mt-16`;
const SvgDotPattern2 = tw(
  SvgDecoratorBlob2
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default ({
  heading = "",
  description = "",
  description1 = "",
  description2 = "",
  description3 = "",
  description4 = "",
  description5 = "",
  imageSrc = DesignIllustration,
  imageCss = null,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Header />
      <Container>
        <TwoColumn style={{ paddingBottom: '0' }}>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            {/* <Paragraph>{description1}</Paragraph> */}
            <Subheading>{description1}</Subheading>
            <SubheadingDesc>{description2}</SubheadingDesc>
            <SubheadingDesc>{description3}</SubheadingDesc>
            <Subheading>{description4}</Subheading>
            <SubheadingDesc>{description5}</SubheadingDesc>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Learning"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        {/*<DecoratorBlob1 />*/}
        {/* <SvgDotPattern1 />
        <SvgDotPattern2 /> */}
      </Container>
    </>
  );
};

import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Desc from "components/internationals/Desc.js";
import BestPart from "components/common/BestPart.js";
import AboutUs from "components/common/AboutUs.js";
import bestPartImage from "../../images/best_part.png";

import HowItWorks from "components/internationals_new/HowItWorks.js";

import WhyUs from "components/internationals_new/WhyUs.js";
import Destinations from "components/internationals_new/Destinations.js";
import OurColleges from "components/internationals_new/OurColleges.js";
import Services from "components/internationals_new/Services.js";

import Top from "components/internationals_new/MainTop.js";
import courseMainImage from "../../images/inter_new.png";
import aboutUsImage from "../../images/aboutus.png"
const imageCss = tw`rounded-4xl`;

class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<AnimationRevealPage>
				<Top
					heading={<>International Students
						Program</>}
					description="Please fill the form below to complete the registration process. Login instructions will be mailed to you once you are enrolled in your course."
					imageSrc={courseMainImage}
					imageCss={imageCss}
					imageDecoratorBlob={true}
				/>
				<hr style={{ width: '83%', marginLeft: '2.5em' }} />

				<Services
					heading={
						<>
							Services
          				</>
					}
				/>
				<Footer />
			</AnimationRevealPage>
		)
	}
}
export default (About);

import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import CoursesOffered from "components/course/Offer.js";

import BestPart from "components/common/BestPart.js";
import AllCourses from "components/course/AllCourses.js";
import Top from "components/course/MainTop.js";
import Footer from "components/common/Footer.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";

import courseMainImage from "../../images/courseMain.png";
import bestPartImage from "../../images/best_part.png";


export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const Description1 = tw.span`inline-block mt-8`;
  const Description2 = tw.span`inline-block mt-8`;
  const Description3 = tw.span`inline-block mt-8`;
  const Description4 = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
  return (
    <AnimationRevealPage>
      <Top
        //heading={<>Ontario's best online  <HighlightedText>schooling portal</HighlightedText></>}
        heading={<>You are making the best decision of your life.</>}
        description="Please go through our courses offered by us. Login instructions will be mailed to you once you are enrolled in your course."
        description1="Regular Semester"
        description2="First week of September - 31 January"
        description3="First week of February - 30 June"
        description4="Summer"
        description5="July & August"


        imageSrc={courseMainImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
      />
      <hr style={{ width: '83%', marginLeft: '2.5em' }} />
      <AllCourses
        heading={
          <>
            {/*         <SubmitButton type="button">Select a Course</SubmitButton> */}
          </>
        }
      />

      <BestPart
        imageSrc={bestPartImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Register"
        watchVideoButtonText="Get a Free Demo"
      />

      <Footer />
    </AnimationRevealPage>
  );
}

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as svg220 } from "images/220.svg";
//import svg220 from "../../images/220.svg";
const Container = tw.div`relative md:px-10`
//const Container = tw.div`relative`

const TwoColumnContainer = styled.div`
${ tw`flex flex-wrap py-1 justify-start m-0 pb-8`}
`;

const Column = styled.div`
  ${tw`my-1 px-1 w-full m-4 lg:m-2 overflow-hidden md:w-5/12 lg:w-5/12 xl:w-5/12`}
`;

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row md:mt-8`;
const Header = tw(SectionHeading)``;
//const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabsControl = tw.div`flex flex-wrap px-2 bg-gray-200 sm:w-full rounded leading-none mt-12 xl:mt-0`;
const TabControl = styled.div`
  ${tw`cursor-pointer py-3 mt-2 sm:mt-0 last:mr-0 text-gray-150 font-normal rounded-sm transition duration-300 text-sm sm:text-sm sm:w-full md:w-1/2 text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`border-b-2! border-primary-100 text-primary-100!`}
  }
`;

const TabContent = tw(motion.div)`flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 text-sm sm:text-xs`;
const CardContainer = tw.div`w-full`;
const Card = tw(motion.a)`rounded-b block p-8 sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-base md:text-lg  font-semibold group-hover:text-primary-500 items-center text-secondary-450`;
const CardContent = tw.p`mt-1 text-sm font-medium text-secondary-250`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob2 = styled(svg220)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-80 w-80 transform translate-x-2/3 text-primary-500`}
`;
const Title = tw.h4`text-3xl font-bold text-gray-700 text-center`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
//const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-blue-100 text-secondary-450 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const textOnLeft = true;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const leftDescription = "* should be same as your Government-issued identification.";
//const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const styles = { width: "100%", height: "182rem" }

const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const HeadingContainer = tw.div`pt-2`;

const SubParagraph = tw.p`mb-8 text-sm font-normal text-secondary-250 mx-auto lg:mx-0`;

const Feature = tw.div`flex items-start flex-row`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block text-center rounded-full p-6 flex-shrink-0 bg-blue-100`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-blue-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }
  .img {
    ${tw`w-8 h-8`}
  }
`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-2`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-8 py-3 rounded bg-primary-500 text-white hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const FeatureText = tw.div`md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`mt-1 text-sm font-semibold text-secondary-250 text-left`;
const FeatureDescription = tw.div`mt-1 text-base text-secondary-250 text-left font-normal`;
const FeatureSubDescription = tw.div`mt-1 text-sm text-secondary-250 text-left`;

const CountryFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm text-left`}
  .feature {
    ${tw`mt-1 first:mt-0 font-normal text-secondary-250`}
  }
`;

const sub_description = "There are two versions of the IELTS exam, the Academic Module and the General Training module. Canadian universities and professional bodies require applicants to sit for the Academic module. The General Training module is for those who are planning to work or take training programs, or for individuals who are planning to migrate to Canada. Candidates are tested in the four core skills of Listening, Speaking, Reading and Writing. Candidates receive a score for each of the four sections of the exam as well as an overall score.";

export default ({
    heading = "",
    cardsRow1 = [
        {
            title: "Tution Fee: ",
            description: "CAD1000 per year",
            sub_desc: "(Subject to Revision)",
            url: ""
        },
        {
            title: "Registration Fee: ",
            description: "CAD100 one time",
            sub_desc: "(Non-Refundable)",
            url: ""
        },
    ],
    tabs = {
        'Grade 9 to 12 Credit Courses Admissions'
            : getRandomCards9(),
        'K to Grade 12 School Admissions': getRandomCards10(),

    }
}) => {

    const tabsKeys = Object.keys(tabs);
    const [activeTab, setActiveTab] = useState(tabsKeys[0]);
    const features = ["Students in Grade 9 or higher", "Age should be between 14 to 19"];
    const features1 = ["Birth Certificate", "All academic certificate and transcripts", "Govt. Photo Id"];
    return (
        <Container>
            <DecoratorBlob2 />
            <ContentWithPaddingXl>
                <HeadingContainer>
                    {/* {subheading && <Subheading>{subheading}</Subheading>} */}
                    <Title>{heading}</Title>
                </HeadingContainer>

                {/* <PrimaryButton as="a">{selectButtonText}</PrimaryButton> */}
                <HeaderRow>
                    <TabsControl>
                        {Object.keys(tabs).map((tabName, index) => (
                            <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                                {tabName}
                            </TabControl>
                        ))}
                    </TabsControl>
                </HeaderRow>


                {tabsKeys.map((tabKey, index) => (
                    <TabContent key={index} variants={{ current: { opacity: 1, scale: 1, display: "flex" }, hidden: { opacity: 0, scale: 0.8, display: "none", } }} transition={{ duration: 0.4 }} initial={activeTab === tabKey ? "current" : "hidden"} animate={activeTab === tabKey ? "current" : "hidden"} className="homeCoursesTabContent">
                        {tabs[tabKey].map((card, index) => (
                            <CardContainer key={index}>
                                <Card className="group courseCard d-flex" initial="rest" whileHover="hover" animate="rest">
                                    <SubParagraph>{sub_description}</SubParagraph>
                                    <FeatureHeading>Eligibility</FeatureHeading>
                                    <CountryFeatures>
                                        {features.map((feature, index) => (
                                            <span key={index} className="feature">
                                                {feature}
                                            </span>
                                        ))}
                                    </CountryFeatures>
                                    <FeatureHeading>Document Required</FeatureHeading>
                                    <CountryFeatures>
                                        {features1.map((feature, index) => (
                                            <span key={index} className="feature">
                                                {feature}
                                            </span>
                                        ))}
                                    </CountryFeatures>
                                    <TwoColumnContainer>
                                        {cardsRow1.map((card, i) => (
                                            <Column style={{
                                                "borderRadius": "10px", "backgroundColor": "#E5F1FF", "boxShadow": "0 17px 34px 0 rgba(182,182,182,0.2)", "height": "140px"
                                            }} key={i}>
                                                <Card href={card.url
                                                } >
                                                    <Feature key={i}>
                                                        <FeatureText>
                                                            <FeatureHeading>{card.title}</FeatureHeading>
                                                            <FeatureDescription>{card.description}</FeatureDescription>
                                                            <FeatureSubDescription>{card.sub_desc}</FeatureSubDescription>
                                                        </FeatureText>
                                                    </Feature>
                                                </Card>
                                            </Column>
                                        ))}
                                    </TwoColumnContainer>
                                    <Actions>
                                        <PrimaryButton href={"/register"} as="a">{"Register"}</PrimaryButton>
                                    </Actions>
                                </Card>
                            </CardContainer>
                        ))}
                    </TabContent>

                ))}

            </ContentWithPaddingXl>

        </Container>
    );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards9 = () => {
    const cards = [
        {
            heading: "Our Team",
            description: "Our team help students learn both formats of the IELTS",
            sub_description: "There are two versions of the IELTS exam, the Academic Module and the General Training module. Canadian universities and professional bodies require applicants to sit for the Academic module. The General Training module is for those who are planning to work or take training programs, or for individuals who are planning to migrate to Canada. Candidates are tested in the four core skills of Listening, Speaking, Reading and Writing. Candidates receive a score for each of the four sections of the exam as well as an overall score.",
        }
    ];

    // Shuffle array
    //return cards.sort(() => Math.random() - 0.5);
    return cards;
};

const getRandomCards10 = () => {
    const cards = [
        {
            heading: "Our Team",
            description: "Our team help students learn both formats of the IELTS",
            sub_description: "There are two versions of the IELTS exam, the Academic Module and the General Training module. Canadian universities and professional bodies require applicants to sit for the Academic module. The General Training module is for those who are planning to work or take training programs, or for individuals who are planning to migrate to Canada. Candidates are tested in the four core skills of Listening, Speaking, Reading and Writing. Candidates receive a score for each of the four sections of the exam as well as an overall score.",
        }




    ];

    // Shuffle array
    //return cards.sort(() => Math.random() - 0.5);
    return cards;
};



const getRandomCards11 = () => {
    const cards = [
        {
            title: "Functions",
            content: "Functions",
            url: "course/course_code=MCR3U"
        },

        {
            title: "English",
            content: "English",
            url: "course/course_code=ENG3U"
        },
    ];

    // Shuffle array
    //return cards.sort(() => Math.random() - 0.5);
    return cards;
};




const getRandomCards12 = () => {
    const cards = [
        {
            title: "English",
            content: "English of University Preparation",
            url: "course/course_code=ENG4U"
        },

        {
            title: "Mathematics",
            content: "Mathematics of Data Management",
            url: "course/course_code=MDM4U"
        },
        {
            title: "Chemistry",
            content: "Chemistry University Preparation",
            url: "course/course_code=SCH4U"
        },
        {
            title: "Physics",
            content: "Physics University Preparation",
            url: "course/course_code=SPH4U"
        },
        {
            title: " Advanced Functions",
            content: " Advanced Functions",
            url: "course/course_code=MHF4U"
        },
        {
            title: "Calculus & Vectors",
            content: "Calculus & Vectors",
            url: "course/course_code=MCV4U"
        }


    ];

    // Shuffle array
    //return cards.sort(() => Math.random() - 0.5);
    return cards;
};



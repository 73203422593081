import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { SectionHeading } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
//import BasicDetails_back_1718_feb_2021 from "components/contact/BasicDetails_back_17-18_feb_2021";

const Quote = tw.h4`text-center font-normal md:text-left text-sm lg:text-base xl:text-lg p-4`;
const SubTitle = tw.h5`leading-relaxed text-sm lg:text-base text-gray-600 md:text-left`;
const Description = tw.p`mt-4 text-center md:text-left text-sm lg:text-base leading-relaxed text-secondary-100`;

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const higlightStyle = { color: '#007bff', 'text-decoration': 'underline' }

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500 items-center`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const Title = tw.h4`text-3xl font-bold text-gray-700 text-left lg:mb-5`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 px-10 lg:py-24`;

const renderContent = (index) => {
	if (index == 0) {
		return <tbody>
			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=MPM1D" style={higlightStyle}>MPM1D</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Principles of Mathematics</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$479</SubTitle>
				</td>
			</tr>
		</tbody>
	} else if (index == 1) {
		return <tbody>
			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=MPM2D" style={higlightStyle}>MPM2D</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Principles of Mathematics</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$479</SubTitle>
				</td>
			</tr>
		</tbody>
	} else if (index == 2) {
		return <tbody>
			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=MCR3U" style={higlightStyle}>MCR3U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Functions</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>
			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=ENG3U" style={higlightStyle}>ENG3U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>English</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>
		</tbody>
	} else {
		return <tbody>
			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=MDM4U" style={higlightStyle}>MDM4U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Data Management</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>
			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=SPH4U" style={higlightStyle}>SPH4U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Physics</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>
			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=SCH4U" style={higlightStyle}>SCH4U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Chemistry</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>

			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=MHF4U" style={higlightStyle}>MHF4U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Advanced Functions</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>

			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=MCV4U" style={higlightStyle}>MCV4U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>Calculus and Vectors </SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>



			<tr className="trBorder">
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle><Link to="course/course_code=ENG4U" style={higlightStyle}>ENG4U</Link></SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>English</SubTitle>
				</td>
				<td className="courseDetailTableTdPadding courseDetailTableTdFirstBorder">
					<SubTitle>$579</SubTitle>
				</td>
			</tr>







		</tbody>
	}
}

export default ({
	heading = "",
	tabs = {
		9: getRandomCards9(),
		10: getRandomCards10(),
		11: getRandomCards11(),
		12: getRandomCards12()
	}
}) => {
	/*
	 * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
	 * as the key and value of the key will be its content (as an array of objects).
	 * To see what attributes are configurable of each object inside this array see the example above for "Starters".
	 */
	const tabsKeys = Object.keys(tabs);
	const [activeTab, setActiveTab] = useState(tabsKeys[0]);

	return (
		<Container>
			<SingleColumn>
				<Title>{heading}</Title>

				<HeaderRow style={{ display: 'inline-block' }}>
					<TabsControl style={{ marginLeft: '0' }}>
						{Object.keys(tabs).map((tabName, index) => (
							<TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
								Grade {tabName}
							</TabControl>
						))}
					</TabsControl>
				</HeaderRow>

				{tabsKeys.map((tabKey, index) => (
					<TabContent key={index} variants={{ current: { opacity: 1, scale: 1, display: "flex" }, hidden: { opacity: 0, scale: 0.8, display: "none", } }} transition={{ duration: 0.4 }} initial={activeTab === tabKey ? "current" : "hidden"} animate={activeTab === tabKey ? "current" : "hidden"}>
						<table style={{ tableLayout: "fixed", width: "100%" }}>
							<thead style={{ backgroundColor: '#F9F9F9' }}>
								<tr className="trBorder">
									<th className="courseDetailTableTdFirstBorder">
										<Quote>
											<center>
												Course Code
											</center>
										</Quote>
									</th>
									<th className="courseDetailTableTdFirstBorder">
										<Quote>
											<center>
												Course Name
											</center>
										</Quote>
									</th>
									<th className="w-1/2">
										<Quote>
											<center>
												Course Fee
											</center>
										</Quote>
									</th>
								</tr>
							</thead>
							{renderContent(index)}
						</table>

					</TabContent>
				))}
			</SingleColumn>
		</Container >
	);
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards9 = () => {
	return 3;
};

const getRandomCards10 = () => {
	return 2;
};

const getRandomCards11 = () => {
	return 0;
};

const getRandomCards12 = () => {
	return 1;
};



import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Desc from "components/lang_course/Desc.js";
import BestPart from "components/common/BestPart.js";
//import AboutUs from "components/common/AboutUs.js";
import bestPartImage from "../../images/best_part.png";

import Features from "components/lang_course/Features.js";
import HowItWorks from "components/lang_course/HowItWorks.js";

import WhyUs from "components/lang_course/WhyUs.js";
import Destinations from "components/lang_course/Destinations.js";
import Jobs from "components/career/Jobs.js";
import Top from "components/career/Top.js";
import mainImage from "../../images/lang_course_main.png";
import MetaTags from 'react-meta-tags';

const imageCss = tw`rounded-4xl`;

class Main extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<AnimationRevealPage>
				<MetaTags>
					<title>Job Postings - Learning Beam Academy</title>
					<meta name="description" content="Are you interested in finding a fulfilling career that makes a difference in the community? Check out what jobs are available -- we are looking for you!" />
					<meta name="keywords" content="IELTS, TOEFL, CELPIP, CAEL, Best Online Schooling in Ontario, Online Schooling in Ontario, Online Schooling, Ontario Best Online virtual high school, Best Ontario Online School, Credit Courses, Grade 9, Grade 10, Grade 11, Grade 12, Free online classes" />
					<meta property="og:title" content="Job Postings - Learning Beam Academy" />
					<meta property="og:description" content="Are you interested in finding a fulfilling career that makes a difference in the community? Check out what jobs are available -- we are looking for you!" />
					<meta property="og:image" content="/static/media/logo.358fb4cd.svg" alt="best online schooling in Ontario" />
				</MetaTags>
				<Top
					heading={<>Join our Tribe</>}
					description="Drop your CV, we will consider your Resume for future Jobs."
					primaryButtonText="Drop CV"
					imageSrc={mainImage}
					imageCss={imageCss}
					imageDecoratorBlob={true}
				/>
				<hr style={{ width: '83%', marginLeft: '2.5em' }} />
				<Jobs />
				{/* <WhyUs />

				<Features
					heading={
						<>
							Key features
          				</>
					}
					imageContainerCss={tw`p-2!`}
					imageCss={tw`w-20! h-20!`}
				/> */}

				{/* <Destinations /> */}
				<Footer />
			</AnimationRevealPage>
		)
	}
}
export default (Main);

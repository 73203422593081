import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-12 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-4/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-12 md:mr-12 lg:mr-16 md:order-last`
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-left md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-serif text-left text-3xl sm:text-4xl font-normal text-center md:text-left leading-tight text-secondary-450`;
const Description = tw.p`mt-4 text-center md:text-left text-sm font-normal leading-relaxed text-secondary-250`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
    props.buttonRounded && tw`rounded-full`
]);
export default ({
    subheading = "",
    heading = (
        <>
            About Learning Beam
        </>
    ),
    description = "Learning Beam overseas studies serving education industry since 2013. We help students to find their dream universities/ colleges/ institutes. We determine to help students to guide correct education path by assessing their current education status and interest. Our services include counselling, evaluating student current education profile, guide them to suitable programs and universities based on their aspirations.",
    description1 = "We have established a strong relationship with some of the best world leading universities from various countries. It enables us to easily advise students and help them get admission into the best schools where they can satisfy their taste for a quality education while enjoying a higher quality of life.",
    description2 = "We have our presence in different abroad locations too i.e. Canada. We understand that studying abroad is big decision for Indian parents, so we offer our extended services to ensure that their kids remain safe when they are in different country. We ensure that we hold your hand even after admission",
    imageSrc = TeamIllustrationSrc,
    buttonRounded = true,
    imageRounded = true,
    imageBorder = false,
    imageShadow = false,
    imageCss = null,
    imageDecoratorBlob = false,
    imageDecoratorBlobCss = null,
    textOnLeft = true
}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
                    {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                        <Description>{description1}</Description>
                        <Description>{description2}</Description>
                        {/* <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
                            {primaryButtonText}
                        </PrimaryButton> */}
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import { SectionDescription } from "components/common/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/common/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/common/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";

import email from "../../images/email.png";


const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full sm:text-xl font-normal`;
const Description = tw(SectionDescription)`w-full text-center mb-6`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

export default ({
  heading = "Flexible Plans.",
  description = "use the login credentials we have mailed you. Once you login you can change your login password in the profile settings section",
  primaryButtonText = "Buy Now"
}) => {

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];

  return (
    <Container>
      <ContentWithPaddingXl style={{ paddingTop: '4rem' }}>
        <HeaderContainer style={{ marginTop: '0' }}>
          <IllustrationContainer>
            <img src={email} />
          </IllustrationContainer>
          <Heading className="headings">{heading}</Heading>
          {/* <Heading>{heading}</Heading> */}
          {/* <Description style={{ "color": "#131415", "fontFamily": "LibreBaskerville-Regular", "fontSize": "16px", "letterSpacing": "0", "lineHeight": "25px", "textAlign": "center" }}>{description}</Description> */}
          <Description>{description}</Description>
          <PrimaryButton as="a" href="/">{primaryButtonText}</PrimaryButton>
        </HeaderContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};

import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/common/Headings.js";
import CoursesDetailsFaq from "components/common/SingleCol.js";

const Container = tw.div`relative`;
const Column = tw.div``;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;


const FAQSContainer = tw.dl`mt-4`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;

const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const Title = tw.h4`text-3xl font-bold text-gray-700 text-center`;
export default ({
  subheading = "",
  heading = "Questions",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "How does online schooling work?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "Can I take a course at Learning Beam Academy?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "How long does a course take?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "What is an upgrade course and how does it work?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    },
    {
      question: "What are the tution fees?",
      answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };
  const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
  const Form = tw.form`mx-auto max-w-xs`;
  const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
  const SubTitle = tw.h4`leading-relaxed font-bold text-lg`;
  const SubTitle1 = tw.h4`leading-relaxed font-bold text-lg pb-4`;
  const JOT_FORM_URL = "https://form.jotform.com/210341258280043";
  const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center`;
  const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
  const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
  ]);
  return (
    <Container>
      <Column>
        <FAQSContainer>
          <FAQContent>
            {subheading ? <Subheading>{subheading}</Subheading> : null}
            <Title>{heading}</Title>
            <CoursesDetailsFaq
              faqs={[
                {
                  question: "How does online schooling work?",
                  answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                },
                {
                  question: "Can I take a course at Learning Beam Academy?",
                  answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                },
                {
                  question: "How long does a course take?",
                  answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                },
                {
                  question: "What is an upgrade course and how does it work?",
                  answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                },
                {
                  question: "What are the tution fees?",
                  answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
                }
              ]} />
          </FAQContent>
        </FAQSContainer>
      </Column>
      {/*<Content style={{paddingBottom:'0'}}>
				<TwoColumn>
					<ImageColumn>
						<FAQContent>
							{subheading ? <Subheading>{subheading}</Subheading> : null}
							<Title>{heading}</Title>
						<FAQSContainer>
						<CoursesDetailsFaq 
							faqs = {[
							{
								question: "How does online schooling work?",
								answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
							},
							{
								question: "Can I take a course at Learning Beam Academy?",
								answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
							},
							{
								question: "How long does a course take?",
								answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
							},
							{
								question: "What is an upgrade course and how does it work?",
								answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
							},
							{
								question: "What are the tution fees?",
								answer: "At Ontario eSecondary (OES), a Ministry of Education inspected online high school, a student can register for an online course at any time. Once registered, they work at their own pace and schedule within the twelve-month timeframe we allot to each course. This means a student could complete the course in 4 weeks, 12 months or anytime in between! All of our online courses contain materials such as PowerPoints, videos, PDFs and interactive content guided by certified instructors covering Ontario curriculum expectations."
							}
							]}
						/>
              </FAQSContainer>
            </FAQContent>
		</ImageColumn>
          <TextColumn>
		  	<center>
		  	<div style={{backgroundColor:'#e5f1ff',height:'100%',borderRadius:'18px',padding:'2em',paddingBottom:'2.5em'}}>
				<SubTitle>
		  			Still have questions?
		  		</SubTitle>
				<SubTitle1>
		  			Feel free to ask us
		  		</SubTitle1>
				<Form style={{maxWidth:'100%'}}>
					<Input type="text" placeholder="Full Name" />
					<Input type="email" placeholder="Emai Id" />
		  			<textarea placeholder="Your question/message" className="textAreaStyle">
					</textarea>	
		  			<PrimaryButton as="a"  style={{width:'200px',height:'60px',padding:'17px 63px 18px 65px',borderRadius:'10px',boxShadow:'0 10px 30px 0 rgba(0, 122, 255, 0.35)',borderStyle:'solid',borderWidth:'0',borderImageSource:'linear-gradient(to bottom, #5caaff, #007aff)',borderImageSlice:'1',backgroundImage:'linear-gradient(to bottom, #007aff, #007aff), linear-gradient(to bottom, #5caaff, #007aff)',backgroundOrigin:'border-box'}} >Submit</PrimaryButton>
				</Form>
		  	</div>
		  	</center>
          </TextColumn>
        </TwoColumn>
      </Content>*/}
    </Container>
  );
};

import React, { Component } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/common/Footer.js";
import Desc from "components/lang_course/Desc.js";
import BestPart from "components/common/BestPart.js";
import AboutUs from "components/common/AboutUs.js";
import bestPartImage from "../../images/best_part.png";

import Features from "components/prof_course/Features.js";
import HowItWorks from "components/prof_course/HowItWorks.js";

import WhyUs from "components/prof_course/WhyUs.js";
import Destinations from "components/prof_course/Destinations.js";
import OurCourses from "components/prof_course/OurCourses.js";
import Top from "components/prof_course/MainTop.js";
import mainImage from "../../images/lang_course_main.png";
import courseMainImage from "../../images/inter_new.png";
import MetaTags from 'react-meta-tags';

const imageCss = tw`rounded-4xl`;

class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<AnimationRevealPage>
				<MetaTags>
					<title>PYTHON, JAVA, POER BI,CYBERARK, QUALITY ASSURANCE, SAILPOINT IDENTITYIQ</title>
					<meta name="description" content="PYTHON, JAVA, POER BI,CYBERARK, QUALITY ASSURANCE, SAILPOINT IDENTITYIQ Online preparation." />
					<meta name="keywords" content="PYTHON, JAVA, POER BI,CYBERARK, QUALITY ASSURANCE, SAILPOINT IDENTITYIQ" />
					<meta property="og:title" content="PYTHON, JAVA, POER BI,CYBERARK, QUALITY ASSURANCE, SAILPOINT IDENTITYIQ" />
					<meta property="og:description" content="PYTHON, JAVA, POER BI,CYBERARK, QUALITY ASSURANCE, SAILPOINT IDENTITYIQ Online preparation." />
					<meta property="og:image" content="/static/media/logo.358fb4cd.svg" alt="best online schooling in Ontario" />
				</MetaTags>
				<Top
					heading={<>Best Professional Skills & Training Courses</>}
					description="Professional or vocational courses are the courses that focus majorly on preparing a student for a job in a particular type of industry."
					imageSrc={courseMainImage}
					imageCss={imageCss}
					imageDecoratorBlob={true}
				/>
				<hr style={{ width: '83%', marginLeft: '2.5em' }} />
				<OurCourses />
				<WhyUs />

				{/* <Features
					heading={
						<>
							Key features
          				</>
					}
					imageContainerCss={tw`p-2!`}
					imageCss={tw`w-20! h-20!`}
				/> */}

				{/* <Destinations /> */}
				<Footer />
			</AnimationRevealPage>
		)
	}
}
export default (About);
